<template lang="pug">
#create-carrier(v-esc-close="cancel" :class="panelClass")
  icon.close-panel(data="@icon/x.svg" @click="cancel" width="16" height="16" title="Close")

  h1 Create Carrier

  form#create-carrier-form(@submit.prevent="create")
    DynamicForm(
      :schema="form._fromConfig"
      :disabled="false"
      v-model="form"
      @status="saveStatus"
      ref="dynamicForm"
    )

    .panel-footer
      button.cancel-btn(type="button" @click="cancel") Cancel
      button.action-btn(type="submit" @click.prevent="create") Create
</template>

<script>
import dynamicFormMixin from '@/mixins/dynamicFormMixin'
import Carrier from '@/models/carrier'

export default {
  mixins: [dynamicFormMixin],
  props: {},

  data() {
    return {
      panelClass: '',
    }
  },

  computed: {},

  watch: {},

  created() {
    this.form = { ...new Carrier() }
  },

  mounted() {
    setTimeout(() => {
      this.panelClass = 'is-visible'
    }, 100)
  },

  methods: {
    cancel,
    create,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cancel() {
  this.panelClass = ''
  setTimeout(() => {
    this.$panel.close('CreateCarrier')
  }, 250)
}

async function create() {
  this.$refs.dynamicForm.validate()

  if (this.status.invalid) {
    return
  }

  const response = await this.$store.dispatch('carriers/create', this.form)

  if (response.status == 422) {
    response.data.forEach(errorMessage => {
      this.$notyf.error(errorMessage)
    })
  } else {
    this.cancel()
    this.$notyf.success('Carrier created')
  }
}
</script>
